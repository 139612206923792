import React from 'react';
import {Article} from '../../../../api/models/Article';
import AnnotationBlock from "../../elements/annotation-block/annotationBlock";
import styles from './blog.module.scss';
import SkeletonAnnotation from "../../elements/annotation-block/skeleton-annotation/skeletonAnnotation";
import { useCustomMetaTags } from '../../../hooks/useMetaTags';


interface Props {
    isLoading: boolean;
    isError:boolean;
    articles: Article[] | null
}

function Blog(props:Props) {

    useCustomMetaTags({ title: `Cutting-Edge IT Insights & Trends - Fivewalls`, description: `Discover expert insights on Slack & Teams integrations, AI automation, and business optimization. Learn how Fivewalls enhances workflows with cutting-edge IT solutions`});

    function ArticleColumn(columnNumber: number) {
        if (props.articles) {
            return props.articles
                .filter(
                    (article: Article, index: number) =>
                        columnNumber === 1
                            ? index % 2 === 0
                            : index % 2 !== 0
                )
                .map((article: Article, index) =>
                    <div className={styles.annotation} key={index}>
                        <AnnotationBlock
                            img={article.preview_image}
                            title={article.title}
                            uid={article.uid}
                            type={article.type}
                        />
                    </div>
                );
        }
    }

    return (
        <section className={styles.section}>
            <div className={styles.container}>
                <div className={styles.column}>
                    {props.isLoading && Array(2)
                        .fill('', 0)
                        .map((item, index) =>
                            <div className={styles.annotation} key={index}>
                                <SkeletonAnnotation type={'article'}/>
                            </div>
                        )}
                    {!props.isLoading && ArticleColumn(1)}
                </div>
                <div className={styles.column}>
                    <div className={styles.titleContainer}>
                        <span>Our Blog</span>
                        <h1 className={styles.title}>Outsourcing and Tech News by Fivewalls</h1>
                    </div>
                    {props.isLoading && Array(1)
                        .fill('', 0)
                        .map((item, index) =>
                            <div className={styles.annotation} key={index}>
                                <SkeletonAnnotation type={'article'}/>
                            </div>
                        )}
                    {!props.isLoading && ArticleColumn(2)}
                </div>
            </div>
        </section>
    );
}

export default Blog;