import React, {useEffect, useState} from 'react';
import {useArticlesCasesByTagApi} from "../../hooks/useArticlesCasesByTagApi";
import {Article} from "../../../api/models/Article";
import {Case} from "../../../api/models/Case";
import BoardBlock from "../elements/board-block/boardBlock";
import {useParams} from "react-router-dom";
import useMetaTags, { useCustomMetaTags } from '../../hooks/useMetaTags';


function TagsBoard() {
    const {tagName: tag} = useParams() as any;
    const [content, setContent] = useState<(Article|Case)[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);

    const [{data: articles, isLoading, isError}] = useArticlesCasesByTagApi({tag, page});

    useEffect(() => {
        articles && setContent([...content, ...articles]);
        return () => {
            setContent([]);
        };
    }, [articles]);

    useEffect(() => {
        if (articles && content.length % 10 !== 0) {
            setHasMore(false);
        }
    });

    useCustomMetaTags({ title: `Articles about ${tag} - Fivewalls`, description: `Explore the latest insights, trends, and expert tips on ${tag}. Stay informed with top articles on ${tag} from the Fivewalls blog.`});

    return (
        <BoardBlock
            content={content}
            hasMore={hasMore}
            currentPage={page}
            setPage={() => setPage(page + 1)}
            isLoading={isLoading}
            blockTitle={`Search results for ${tag}`}
        />
    );
}

export default TagsBoard;